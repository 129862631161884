// Pulls variables out of the URL
function _getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    // console.log('Query variable %s not found', variable);
}
 
if (typeof _getQueryVariable('s') != 'undefined') {
        $(document).ready(function() {
                $('#stateDropDown option[value="' + _getQueryVariable('s') + '"]').attr('selected', true);
                $('#stateDropDown').trigger('change');
        });
}
